<template>
<div>
  <v-card flat outlined :class="`my-2 pa-2 ${ruleViolation ? 'red lighten-5':''}`" style="min-height: 80px">
    <v-chip
      flat
      outlined
      color="primary"
      v-for="(item, i) in value"
      :key="item.id"
      close
      @click:close="value.splice(i, 1)"
      class="ma-2"
      small
    >
      <v-icon small left>
        {{ item.icon }}
      </v-icon>
      {{ item.name }}
    </v-chip>
  </v-card>
  <div class="text-caption red--text" v-if="ruleViolation">标签数须在{{this.min}} ~ {{this.max}}之间(含)</div>
  <div class="text-caption">点击下面标签选择<span v-if="max"> 最多:{{ max }}个</span><span v-if="max"> 最少:{{ min }}个</span></div>
  <v-divider></v-divider>
  <v-chip
    class="ma-2"
    v-for="item in tagsNotSelected"
    :key="item.id"
    @click="value.push({id: item.id, name: item.name, icon: item.icon})"
    small
  >
    <v-icon small left>
      {{ item.icon }}
    </v-icon>
    {{ item.name }}
  </v-chip>
</div>
</template>

<script>
export default {
  props: { // make it possible to pass in parameter <panel title="课程模版">
    tags: {
      type: Array,
      default: () => { return [] }
    },
    value: { // tagsSelected
      type: Array,
      default: () => { return [] }
    },
    max: {
      type: Number,
      default: () => { return null }
    },
    min: {
      type: Number,
      default: () => { return null }
    }
  },
  components: {
  },
  computed: {
    tagsNotSelected: {
      get: function () {
        let tagSelectedIds = this.value.map(tag => tag.id)
        return this.tags.filter(tag => !tagSelectedIds.includes(tag.id))
      },
      // setter when this value is updated
      set: function (tags) {
      }
    },
    ruleViolation: {
      get: function () {
        let ruleViolationFlag = false
        if (this.max) {
          if (this.value.length > this.max) {
            ruleViolationFlag = true
          }
        }
        if (this.min) {
          if (this.value.length < this.min) {
            ruleViolationFlag = true
          }
        }
        return ruleViolationFlag
      }
    }
  },
  watch: {
    value: function (newValue) {
      this.$emit('input', newValue)
    },
    ruleViolation: function (newViolationState) {
      this.$emit('rule-violation-state-change', !newViolationState)
    }
  },
  data () {
    return {
      isValide: false
    }
  },
  async mounted () {
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
