<template>
<div>
  <h1 class="title grey--text">编辑项目</h1>
  <div class="subheader">{{autoSaveStatus}}</div>
  <v-card flat class="my-5">
    <!-- 修改建议 -->
    <v-alert
      v-if="[1].includes(programInfo.programApprovalStatusCode) && programInfo.programApprovalImprovementComment"
      border="left"
      color="orange"
      dismissible
      text
      icon="feedback"
      >
      <strong>修改建议</strong>
      <p>{{programInfo.programApprovalImprovementComment}}</p>
    </v-alert>
    <v-layout flex justify-center fluid class="">
      <v-flex xs11 class="d-flex justify-center">
        <v-form
          ref="programForm"
          v-model="isFormVisiblyValid"
          lazy-validation
          style=""
        >
          <!-- 机构与教师 -->
          <v-select
            v-model="programOrganizationSelect"
            :items="organizationItems"
            item-text="name"
            item-value="id"
            label="机构名称"
            return-object
            outlined
            persistent-hint
            :rules="[rules.objectSelectionRequired]"
            prepend-icon="business"
            class="xs-10"
          ></v-select>
          <v-select
            v-model="programInfo.tutorId"
            :items="tutorItems"
            item-text="tutor"
            item-value="tutorId"
            label="任课教师"
            :rules="[rules.objectSelectionRequired]"
            outlined
            hint="该培训项目的任课私教"
            persistent-hint
            prepend-icon="person"
          ></v-select>
          <v-stepper alt-labels vertical v-model="step">

            <!-- 基本信息 -->
            <v-stepper-step step="1"
              :rules="[() => !((!programInfo.title) || (!programInfo.description) || (!programInfo.tags.length))]"
              editable
            >
              <span><v-icon class="mr-2">highlight</v-icon>基本信息</span>
              <small v-if="(!programInfo.title) || (!programInfo.description) || (!programInfo.tags.length)" class="error--text">请检查此步所填信息</small>
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-card class="my-6 pa-5 mx-auto" max-width="550" outlined>
                <v-text-field
                  label="项目名称"
                  :rules="rules.progTitleRules"
                  counter="50"
                  v-model="programInfo.title"
                  prepend-icon="ballot"
                ></v-text-field>

                <v-textarea
                  label="项目描述"
                  counter="150"
                  outlined
                  v-model="programInfo.description"
                  :rules="rules.progDescriptionRules"
                  class="pa-2"
                  height="150"
                ></v-textarea>
                <!-- 项目标签选择 -->
                <v-subheader><v-icon>style</v-icon>项目标签</v-subheader>
                <v-divider class="mb-5"></v-divider>
                <y-tag-selector
                  :tags="programTags"
                  :max=5
                  :min=1
                  v-model="programInfo.tags"
                  v-on:rule-violation-state-change="isProgramTagValidHandler($event)"
                />
                <v-card-actions flex>
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="step ++"
                    class="mx-2"
                  >
                    下一步
                    <v-icon right>navigate_next</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <!-- 项目头图 -->
            <v-stepper-step step="2"
              :rules="[() => !((!programInfo.imageFileStorageId))]"
              editable>
              <span><v-icon class="mr-2">auto_awesome</v-icon>项目头图</span>
              <small v-if="!programInfo.imageFileStorageId" class="error--text">请检查此步所填信息</small>
            </v-stepper-step>
            <v-stepper-content step="2">
              <v-card class="my-6 pa-5 mx-auto" outlined>
                <div>
                  <v-card v-if="!programInfo.imgUrl"
                    flat
                    color="grey lighten-4"
                    style="min-height: 150px;"
                    class="text-align-vertical-center">
                    <span class="grey--text"><v-icon>image</v-icon> 项目头图</span>
                  </v-card>
                  <v-img
                    v-if="programInfo.imgUrl"
                    class=""
                    :src="programInfo.imgUrl" alt=""
                    :aspect-ratio="1/1"
                    style="width: 100%;"
                  >
                  </v-img>
                </div>
                <!-- 修改项目头图对话框-->
                <div v-if="!programInfo.imgUrl" class="text-caption error--text">{{rules.progImgRules.msg}}</div>
                <v-dialog
                  v-model="dialogProgramImage"
                  style=""
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      class="ma-5"
                    >
                      <v-icon>image</v-icon>上传头图
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">
                      上传项目头图
                    </v-card-title>
                    <v-card-actions flex>
                      <v-btn v-if="imgURL">
                        <clipper-upload v-model="imgURL">更换</clipper-upload>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="grey"
                        text
                        @click="cancelClippingProgramImage"
                      >
                        取消
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="updateProgramImage"
                      >
                        确定
                      </v-btn>
                    </v-card-actions>
                    <v-divider></v-divider>
                    <clipper-basic class="my-clipper" ref="clipper" :src="imgURL" :init-width="100" :ratio='1/1' :touch-create="false">
                      <clipper-upload class="headline" slot="placeholder" v-model="imgURL">
                        <v-btn class="placeholder" >
                          选择图片
                        </v-btn>
                      </clipper-upload>
                    </clipper-basic>
                    <!-- <div>
                        <div>preview:</div>
                        <clipper-preview name="my-preview" class="my-clipper">
                            <div class="placeholder" slot="placeholder">preview area</div>
                        </clipper-preview>
                    </div> -->
                    <v-divider></v-divider>
                  </v-card>
                </v-dialog>
                <v-alert
                  border="left"
                  type="info"
                  dismissible
                  text
                  >
                  <p>建议按 1:1 比例设计项目头图，图片长边不大于 1080px (否则会被压缩)，并突出项目特点。</p>
                </v-alert>
              <v-card-actions flex>
                <v-btn
                  outlined
                  color="primary"
                  @click="step --"
                  class="mx-2"
                >
                  <v-icon left>navigate_before</v-icon>
                  上一步
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  color="primary"
                  @click="step ++"
                  class="mx-2"
                >
                  下一步
                  <v-icon right>navigate_next</v-icon>
                </v-btn>
              </v-card-actions>
              </v-card>
            </v-stepper-content>

            <!-- 课时设置 -->
            <v-stepper-step step="3"
              :rules="[() => !((!programInfo.moduleLengthMin) || (!programInfo.sessionModuleQuantity) || (!programInfo.maxStudentNumberPerSession))]"
              editable>
              <span><v-icon class="mr-2">av_timer</v-icon>课时设置</span>
              <small v-if="(!programInfo.moduleLengthMin) || (!programInfo.sessionModuleQuantity) || (!programInfo.maxStudentNumberPerSession)" class="error--text">请检查此步所填信息</small>
            </v-stepper-step>
            <v-stepper-content step="3">
              <v-card class="my-6 pa-5 mx-auto" max-width="550" outlined>
                <v-subheader>每次活动总时长 = 课时数 X 每课时分钟数</v-subheader>
                <v-divider class="mb-5"></v-divider>
                <v-select
                  :items="moduleLengthMinItems"
                  label="每课时包含分钟数"
                  dense
                  outlined
                  :rules="[rules.selectionRequired]"
                  v-model="programInfo.moduleLengthMin"
                ></v-select>
                <v-select
                  :items="sessionModuleQuantityItems"
                  label="每次课使用课时数"
                  dense
                  outlined
                  v-model="programInfo.sessionModuleQuantity"
                  :rules="[rules.selectionRequired]"
                ></v-select>
                <span class="text-subtitle-2, grey--text" v-if="programInfo.moduleLengthMin && programInfo.sessionModuleQuantity">设定单次课用时: {{programInfo.moduleLengthMin * programInfo.sessionModuleQuantity}} 分钟</span>
                <v-subheader> 班级容量</v-subheader>
                <v-divider class="mb-5"></v-divider>
                <v-select
                  :items="maxStudentNumberPerSessionItems"
                  label="每班学员人数上限"
                  dense
                  outlined
                  v-model="programInfo.maxStudentNumberPerSession"
                  :rules="[rules.selectionRequired]"
                ></v-select>
                <v-subheader> 允许插班设置 </v-subheader>
                <v-divider class="mb-5"></v-divider>
                <v-alert
                  border="left"
                  type="info"
                  dismissible
                  text
                  >
                  <p>可插班活动可持续招生，为私教带来较大便利。因此，鼓励设计可插班活动：要求尽可能减少活动之间的依赖。如必要，鼓励对项目进行拆解，使之符合可插班要求。</p>
                </v-alert>
                <v-switch
                  :label="`可插班设置: ${programInfo.interimAdmission ? '是' : '否'}`"
                  v-model="programInfo.interimAdmission"
                ></v-switch>
                <v-card-actions flex>
                  <v-btn
                    outlined
                    color="primary"
                    @click="step --"
                    class="mx-2"
                  >
                    <v-icon left>navigate_before</v-icon>
                    上一步
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="step ++"
                    class="mx-2"
                  >
                    下一步
                    <v-icon right>navigate_next</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <!-- 训练目标 -->
            <v-stepper-step step="4"
              :rules="[() => !((!programInfo.objectiveTags) || (!programInfo.trainingObjective) || (!programInfo.trainingApproach) || (!programInfo.totalModuleQuantity))]"
              editable>
              <span><v-icon class="mr-2">sports_score</v-icon>训练目标</span>
              <small v-if="(!programInfo.objectiveTags) || (!programInfo.trainingObjective) || (!programInfo.trainingApproach) || (!programInfo.totalModuleQuantity)" class="error--text">请检查此步所填信息</small>
            </v-stepper-step>
            <v-stepper-content step="4">
              <v-card class="my-6 pa-5 mx-auto" max-width="550" outlined>
                <v-subheader>训练目标：学员在完成本项目后能获得什么能力</v-subheader>
                <v-divider class="mb-5"></v-divider>
                <v-textarea
                  label="可帮助学员获得能力"
                  counter="150"
                  outlined
                  v-model="programInfo.trainingObjective"
                  :rules="rules.progTrainingObjectiveRules"
                  class="pa-2"
                  height="100"
                ></v-textarea>
                <v-subheader><v-icon>style</v-icon>训练目标标签</v-subheader>
                <v-divider class="mb-5"></v-divider>
                <y-tag-selector
                  :tags="programObjectiveTags"
                  :max=5
                  :min=1
                  v-model="programInfo.objectiveTags"
                  v-on:rule-violation-state-change="isProgramObjectiveTagValidHandler($event)"
                />
                <v-subheader>训练手段</v-subheader>
                <v-divider class="mb-5"></v-divider>
                <v-textarea
                  label="训练手段描述"
                  counter="150"
                  outlined
                  v-model="programInfo.trainingApproach"
                  :rules="rules.progTrainingApproachRules"
                  class="pa-2"
                  height="100"
                ></v-textarea>
                <v-subheader>总培训时长</v-subheader>
                <v-divider class="mb-2"></v-divider>
                <v-alert
                  border="left"
                  type="info"
                  dismissible
                  text
                  >
                  <p>为完成上述教学目标所需学员出席的总 次 数(对应课时数已自动换算)，请尽力给出准确的估计，并为复习等教学活动留出合理的余量 (20%左右)。</p>
                </v-alert>
                <v-slider
                  v-model="progTotalSessionNumber"
                  class="align-center, mt-5"
                  :max="100"
                  :min="1"
                  thumb-label
                  :label="`估计总次数`"
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="progTotalSessionNumber"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 45px"
                    ></v-text-field>
                  </template>
                </v-slider>
                <div class="text-subtitle-2, grey--text">设定的教学总时长: {{programInfo.totalModuleQuantity}}课时 ({{programInfo.moduleLengthMin}}分钟/课时) | 总时长: {{programInfo.totalModuleQuantity * programInfo.moduleLengthMin / 60}}小时</div>
                <v-card-actions flex>
                  <v-btn
                    outlined
                    color="primary"
                    @click="step --"
                    class="mx-2"
                  >
                    <v-icon left>navigate_before</v-icon>
                    上一步
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="step ++"
                    class="mx-2"
                  >
                    下一步
                    <v-icon right>navigate_next</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <!-- 目标学员 -->
            <v-stepper-step step="5"
              :rules="[() => !((!programInfo.prerequisite) || (!programInfo.prerequisiteTags) || (!programInfo.targetStudentTags) || (!programInfo.targetStudent) || (!programInfo.entryStudentAgeMonthLowerLimit) || (!programInfo.entryStudentAgeMonthUpperLimit))]"
              editable>
              <span><v-icon class="mr-2">person_search</v-icon>目标学员</span>
              <small v-if="(!programInfo.prerequisite) || (!programInfo.prerequisiteTags) || (!programInfo.targetStudentTags) || (!programInfo.targetStudent) || (!programInfo.entryStudentAgeMonthLowerLimit) || (!programInfo.entryStudentAgeMonthUpperLimit)" class="error--text">请检查此步所填信息</small>
            </v-stepper-step>
            <v-stepper-content step="5">
              <v-card class="my-6 pa-5 mx-auto" max-width="550" outlined>
                <v-subheader>目标学员描述</v-subheader>
                <v-divider class="mb-5"></v-divider>
                <v-textarea
                  label="谁适合参加本项目训练，他她们有哪些需求？"
                  outlined
                  counter="150"
                  v-model="programInfo.targetStudent"
                  :rules="rules.progTargetStudentRules"
                  class="pa-2"
                  height="100"
                ></v-textarea>
                <v-subheader><v-icon>style</v-icon>目标学员画像标签</v-subheader>
                <v-divider class="mb-5"></v-divider>
                <y-tag-selector
                  :tags="programTargetStudentTags"
                  :max=5
                  :min=1
                  v-model="programInfo.targetStudentTags"
                  v-on:rule-violation-state-change="isProgramTargetStudentTagValidHandler($event)"
                />
                <v-subheader>年龄要求({{entryStudentAgeRangeMonth[0]/12}}~{{entryStudentAgeRangeMonth[1]/12}} 岁)</v-subheader>
                <v-divider class="mb-5"></v-divider>
                <v-range-slider
                  v-model="entryStudentAgeRangeMonth"
                  min="0"
                  max="132"
                  :rules="rules.progEntryStudentAgeRangeMonthRules"
                  step="6"
                  tick-size="0"
                  :tick-labels="entryAgeRangeTickLabels"
                  ticks="always"
                  label="入班年龄"
                  thumb-label
                  track-color="grey"
                  track-fill-color="primary"
                >
                  <template v-slot:thumb-label="{ value }">
                    {{ value/12+'岁' }}
                  </template>
                </v-range-slider>
                <v-subheader>前置要求</v-subheader>
                <v-divider class="mb-5"></v-divider>
                <v-textarea
                  label="参加本项目前需要具备哪些能力"
                  outlined
                  counter="150"
                  v-model="programInfo.prerequisite"
                  :rules="rules.progPrerequisiteRules"
                  prepend-icon="note"
                  class="pa-2"
                  height="100"
                ></v-textarea>
                <v-subheader><v-icon>style</v-icon>前置要求标签</v-subheader>
                <v-divider class="mb-5"></v-divider>
                <y-tag-selector
                  :tags="programPrerequisiteTags"
                  :max=5
                  :min=1
                  v-model="programInfo.prerequisiteTags"
                  v-on:rule-violation-state-change="isProgramPrerequisiteTagValidHandler($event)"
                />
                <v-card-actions flex>
                  <v-btn
                    outlined
                    color="primary"
                    @click="step --"
                    class="mx-2"
                  >
                    <v-icon left>navigate_before</v-icon>
                    上一步
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="step ++"
                    class="mx-2"
                  >
                    下一步
                    <v-icon right>navigate_next</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <!-- 活动分类 -->
            <v-stepper-step step="6"
              :rules="[() => !((!programInfo.categoryCode || !programInfo.targetSkillLevelCode))]"
              editable>
              <span><v-icon class="mr-2">folder_special</v-icon>类别与难度</span>
              <small v-if="(!programInfo.categoryCode)" class="error--text">请检查此步所填信息</small>
            </v-stepper-step>
            <v-stepper-content step="6">
              <v-card class="my-6 pa-5 mx-auto" max-width="550" outlined>
                <v-subheader>类别</v-subheader>
                <v-divider class="mb-5"></v-divider>
                <v-select
                  v-model="programCategory3Select"
                  :hint="`${programCategory3Select.description}`"
                  :items="programCategory3"
                  item-text="category3"
                  item-value="code"
                  label="选择最恰当的分类"
                  outlined
                  persistent-hint
                  return-object
                  single-line
                  :rules="[rules.selectionRequired]"
                ></v-select>
                <v-select
                  v-if="programCategory3Select.code != null"
                  v-model="programCategory2Select"
                  :hint="`${programCategory2Select.description}`"
                  :items="programCategory2"
                  item-text="category2"
                  item-value="code"
                  label="选择最恰当的分类"
                  outlined
                  persistent-hint
                  return-object
                  single-line
                  :rules="[rules.selectionRequired]"
                ></v-select>
                <v-select
                  v-if="programCategory2Select.code != null"
                  v-model="programCategorySelect"
                  :hint="`${programCategorySelect.description}`"
                  :items="programCategory"
                  item-text="category"
                  item-value="code"
                  label="选择最恰当的分类"
                  outlined
                  persistent-hint
                  return-object
                  single-line
                  :rules="[rules.selectionRequired]"
                ></v-select>
                <div v-if="programSkillLevel.length > 0">
                  <v-subheader>难度</v-subheader>
                  <v-divider class="mb-5"></v-divider>
                  <v-select
                    v-model="programTargetSkillLevelSelect"
                    :hint="`${programTargetSkillLevelSelect.levelExplaination}`"
                    :items="programSkillLevel"
                    item-text="level"
                    item-value="levelCode"
                    label="选择最恰当活动目标水平等级"
                    outlined
                    persistent-hint
                    return-object
                    single-line
                    :rules="[rules.selectionRequired]"
                  ></v-select>
                  <div v-if="programTargetSkillLevelSelect.galleryItems.length > 0">
                    <v-subheader><span class="grey--text">[难度] </span> {{ ' ' + programTargetSkillLevelSelect.category }}类-{{ programTargetSkillLevelSelect.subLevelWithinCategory }}</v-subheader>
                    <v-subheader>[示例]</v-subheader>
                    <v-carousel
                      cycle
                      hide-delimiters
                      height="auto"
                      v-model="programTargetSkillLevelSelectDisplayingGalleryIndex"
                    >
                      <v-carousel-item
                        v-for="(galleryItem) in programTargetSkillLevelSelect.galleryItems"
                        :key="galleryItem.fileName"
                      >
                        <video controls autoplay muted style="width: 100%;">
                          <source type="video/mp4" :src="galleryItem.url">
                        </video>
                      </v-carousel-item>
                    </v-carousel>
                    <span class="text-caption grey--text">{{programTargetSkillLevelSelectInfo.galleryItems[programTargetSkillLevelSelectDisplayingGalleryIndex].description}}</span>
                  </div>
                </div>
                <v-card-actions flex>
                  <v-btn
                    outlined
                    color="primary"
                    @click="step --"
                    class="mx-2"
                  >
                    <v-icon left>navigate_before</v-icon>
                    上一步
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="step ++"
                    class="mx-2"
                  >
                    下一步
                    <v-icon right>navigate_next</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <!-- 媒体内容 -->
            <v-stepper-step step="7"
              :rules="[() => !((!programInfo.descriptionDetail))]"
              editable>
              <span><v-icon class="mr-2">info</v-icon>媒体内容</span>
              <small v-if="(!programInfo.descriptionDetail)" class="error--text">请检查此步所填信息</small>
            </v-stepper-step>
            <v-stepper-content step="7">
              <v-card class="my-6 pa-5 mx-auto" max-width="550" outlined>
                <!-- 媒体内容按文字图片与视频显示 -->
                <div v-for="(media, index) in programInfo.medias" :key="media.id">
                  <v-card class="ma-2">
                    <!-- 图片显示 -->
                    <v-img
                      v-if="media.categoryCode === MediaCategoryLookup.IMAGE"
                      :src="media.url"
                    ></v-img>
                    <!-- 视频显示 -->
                    <video
                      v-else-if="media.categoryCode === MediaCategoryLookup.VIDEO"
                      controls style="width: 100%;"
                      :src="media.url"
                    >
                      <source type="video/mp4" :src="media.url">
                    </video>
                    <!-- 文字显示 -->
                    <v-textarea
                      v-else
                      v-model="media.text"
                      outlined
                      name="input-7-4"
                      label=""
                      :value="media.text"
                      @change="debouncedAutoSaveProgramMedia({ media, index })"
                    ></v-textarea>
                    <v-card-actions>
                      <!-- 删除 -->
                      <v-btn @click="deleteMedia({ media, index })"><v-icon left>delete</v-icon>删除</v-btn>
                      <!-- 上移 -->
                      <v-btn :disabled="index === 0" @click="swapMedia({ media1: media, media2: programInfo.medias[index - 1], media1Index: index, media2Index: index-1 })"><v-icon left>arrow_circle_up</v-icon>上移</v-btn>
                      <!-- 下移 -->
                      <v-btn :disabled="index + 1 === programInfo.medias.length" @click="swapMedia({ media1: media, media2: programInfo.medias[index + 1], media1Index: index, media2Index: index+1 })"><v-icon left>arrow_circle_down</v-icon>下移</v-btn>
                    </v-card-actions>
                  </v-card>

                  <!-- 插入媒体的按钮 -->
                  <!-- 图片 -->
                  插入
                  <v-btn
                    class="mx-2"
                    outlined
                    text
                    @click="$refs.imageFileInput[index].click()"
                  >
                    <v-icon left>
                      image
                    </v-icon>
                    图片
                  </v-btn>
                  <input
                    :ref="`imageFileInput`"
                    @input="insertMedia({
                      mediaCategory: MediaCategoryLookup.IMAGE,
                      index: programInfo.medias.length - 1 >= 0 ? index : null,
                      text: '图片',
                      refsInputFunIsArray: true
                    })"
                    type="file"
                    accept="image/*"
                    style="display: none"
                  >
                  <!-- 视频 -->
                  <v-btn
                    class="mx-2"
                    outlined
                    text
                    @click="$refs.videoFileInput[index].click()"
                  >
                    <v-icon left>
                      videocam
                    </v-icon>
                    视频
                  </v-btn>
                  <input
                    type="file"
                    accept="video/*"
                    ref="videoFileInput"
                    style="display: none"
                    @input="insertMedia({
                      mediaCategory: MediaCategoryLookup.VIDEO,
                      index: programInfo.medias.length - 1 >= 0 ? index : null,
                      text: '视频',
                      refsInputFunIsArray: true
                    })"
                  >
                  <!-- 文字 -->
                  <v-btn
                    class="mx-2"
                    @click="insertMedia({
                      text: '',
                      mediaCategory:MediaCategoryLookup.TEXT,
                      index: programInfo.medias.length - 1 >= 0 ? index : null,
                    })"
                    outlined
                    text
                  >
                    <v-icon left>
                      title
                    </v-icon>
                    文字
                  </v-btn>
                </div>
                <!-- 最底端媒体插入按钮,只有媒体空白时出现 -->
                <div v-if="programInfo.medias.length == 0">
                  插入第一个媒体块儿
                  <!-- 图片 -->
                  <v-btn
                    class="mx-2"
                    outlined
                    text
                    @click="$refs.imageFileInput.click()"
                  >
                    <v-icon left>
                      image
                    </v-icon>
                    图片
                  </v-btn>
                  <input
                    ref="imageFileInput"
                    @input="insertMedia({
                      mediaCategory: MediaCategoryLookup.IMAGE,
                      index: programInfo.medias.length - 1 >= 0 ? programInfo.medias.length - 1 : null,
                      text: '图片'
                    })"
                    type="file"
                    accept="image/*"
                    style="display: none"
                  >
                  <!-- 视频 -->
                  <v-btn
                    class="mx-2"
                    outlined
                    text
                    @click="$refs.videoFileInput.click()"
                  >
                    <v-icon left>
                      videocam
                    </v-icon>
                    视频
                  </v-btn>
                  <input
                    type="file"
                    accept="video/*"
                    ref="videoFileInput"
                    style="display: none"
                    @input="insertMedia({
                      mediaCategory: MediaCategoryLookup.VIDEO,
                      index: programInfo.medias.length - 1 >= 0 ? programInfo.medias.length - 1 : null,
                      text: '视频'
                    })"
                  >
                  <!-- 文字 -->
                  <v-btn
                    class="mx-2"
                    @click="insertMedia({
                      text: '',
                      mediaCategory:MediaCategoryLookup.TEXT,
                      index: programInfo.medias.length - 1 >= 0 ? programInfo.medias.length - 1 : null,
                    })"
                    outlined
                    text
                  >
                    <v-icon left>
                      title
                    </v-icon>
                    文字
                  </v-btn>
                </div>
                <v-card-actions flex>
                  <v-btn
                    outlined
                    color="primary"
                    @click="step --"
                    class="mx-2"
                  >
                    <v-icon left>navigate_before</v-icon>
                    上一步
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="step ++"
                    class="mx-2"
                  >
                    下一步
                    <v-icon right>navigate_next</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <!-- 详细描述 -->
            <v-stepper-step step="8"
              :rules="[() => !((!programInfo.descriptionDetail))]"
              editable>
              <span><v-icon class="mr-2">info</v-icon>补充说明</span>
              <small v-if="(!programInfo.descriptionDetail)" class="error--text">请检查此步所填信息</small>
            </v-stepper-step>
            <v-stepper-content step="8">
              <v-alert
                border="left"
                type="success"
                dismissible
                text
                >
                <p>可追加图文补充说明，该说明将出现在项目购买页-项目详情部分，鼓励在此处上传教学相关图片。</p>
              </v-alert>
              <y-editor
                class="editor my-5"
                style="min-height: 200px;"
                v-model="programInfo.descriptionDetail"
              />
              <v-card-actions flex>
                <v-btn
                  outlined
                  color="primary"
                  @click="step --"
                  class="mx-2"
                >
                  <v-icon left>navigate_before</v-icon>
                  上一步
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color='primary'
                  :disabled='!isFormValid'
                  @click="approvalRequest"
                >
                  <v-progress-circular
                    v-if="showWait"
                    indeterminate
                    color="white"
                    :size="20"
                    :width="2"
                  ></v-progress-circular>
                  <span v-if="showWait">&nbsp;</span>
                  <v-icon
                    left
                    dark
                    v-if="!showWait"
                  >
                    cloud_done
                  </v-icon>
                  提交审核
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper>

          <v-divider></v-divider>
        </v-form>
      </v-flex>
    </v-layout>
    <v-fab-transition>
      <v-btn
        color='primary'
        :disabled='!isFormValid'
        @click="approvalRequest"
        fixed
        bottom
        right
      >
        <v-progress-circular
          v-if="showWait"
          indeterminate
          color="white"
          :size="20"
          :width="2"
        ></v-progress-circular>
        <span v-if="showWait">&nbsp;</span>
        <v-icon
          left
          dark
          v-if="!showWait"
        >
          cloud_done
        </v-icon>
        提交审核
      </v-btn>
    </v-fab-transition>
  </v-card>
  <!-- display overlay on the whole page while loading -->
  <v-overlay :value="$store.state.queryingState">
    <v-progress-circular
      indeterminate
      color=""
    ></v-progress-circular>
  </v-overlay>
</div>
</template>

<script>
import Vue from 'vue'
import {
  debounce,
  merge as _merge
  // assign as _assign
} from 'lodash'
import { clipperBasic } from 'vuejs-clipper'
import resizeImage from '@/plugins/imageResize.js'
import ProgramService from '@/services/ProgramService'
import YEditor from '@/components/YEditor'
import YTagSelector from '@/components/YTagSelector'
import AliOssService from '../services/AliOssService'
import helper from '../lib/helper'
import { MediaCategoryLookup, ProgramCommonPropertyStatusLookup } from '../enums'
const config = require('../config/config')

export default {
  components: {
    YEditor,
    clipperBasic,
    YTagSelector
  },
  watch: {
    programCategory3Select: async function () { // 在用户选择上一级分类内容后查询下一级分类内容
      console.log('programCategory3Select is being changed')
      this.programCategory2 = await this.getProgramCategory2([this.programCategory3Select.code])
      // make auto selection when programInfo already has category2 info. This is for existing program which only has
      if (this.programInfo.category2Code) {
        console.log(`this.programInfo.category2Code: ${this.programInfo.category2Code}`)
        let programCategory2Select = this.programCategory2.filter(obj => {
          return obj.code === this.programInfo.category2Code
        })
        if (programCategory2Select.length !== 0) { // found match
          console.log(programCategory2Select[0])
          this.programCategory2Select = programCategory2Select[0]
        } else { // category 3 selection is changed
          this.programCategory2Select = { description: '' }
        }
      }
    },
    programCategory2Select: async function () { // 在用户选择上一级分类内容后查询下一级分类内容
      console.log('programCategory2Select is being changed')
      if (this.programCategory2Select.code) {
        this.programCategory = await this.getProgramCategory([this.programCategory2Select.code])
      }
      // make auto selection when programInfo already has category info
      if (this.programInfo.categoryCode) {
        console.log(`this.programInfo.categoryCode: ${this.programInfo.categoryCode}`)
        let programCategorySelect = this.programCategory.filter(obj => {
          return obj.code === this.programInfo.categoryCode
        })
        if (programCategorySelect.length !== 0) { // found match
          console.log(programCategorySelect[0])
          this.programCategorySelect = programCategorySelect[0]
        }
      }
      // 更新等级信息
      if (this.programCategory2Select.code) {
        this.programSkillLevel = await this.getProgramSkillLevelsDefinition({ programCategory2Codes: [this.programCategory2Select.code], fetchGallery: 1 })
        this.programTargetSkillLevelSelectInfo = { levelExplaination: '', galleryItems: [] }
      }
    },
    programOrganizationSelect: async function () {
      this.programInfo.organizationId = this.programOrganizationSelect.id
      this.tutorItems = helper.camelize(await this.getTutorsByOrganizationId(this.programOrganizationSelect.id))
      if (this.tutorItems.length === 1) {
        this.programInfo.tutorId = this.tutorItems[0].tutorId
      }
    },
    programInfo: {
      async handler (newProgramInfo) {
        if (this.autoSaveStartToMonitorChanges) {
          console.log('programInfo is being changed')
          this.autoSaveStatus = '正在编辑...'
          this.debouncedAutoSaveProgram(newProgramInfo)
        }
      },
      deep: true
    }
  },
  computed: {
    priceFen: function () {
      return this.priceYuan * 100
    },
    entryStudentAgeRangeMonth: {
      get: function () {
        return [this.programInfo.entryStudentAgeMonthLowerLimit, this.programInfo.entryStudentAgeMonthUpperLimit]
      },
      // setter when this value is updated
      set: function (newAgeRange) {
        this.programInfo.entryStudentAgeMonthLowerLimit = newAgeRange[0]
        this.programInfo.entryStudentAgeMonthUpperLimit = newAgeRange[1]
      }
    },
    progTotalSessionNumber: {
      get: function () {
        return this.programInfo.totalModuleQuantity / this.programInfo.sessionModuleQuantity
      },
      // setter when this value is updated
      set: function (newValue) {
        this.programInfo.totalModuleQuantity = newValue * this.programInfo.sessionModuleQuantity
      }
    },
    programCategorySelect: { // when user choose final category, set program's category.
      get: function () {
        return {
          code: this.programInfo.categoryCode,
          description: ''
        }
      },
      // setter when this value is updated
      set: function (newValue) {
        if (newValue.code) {
          this.programInfo.categoryCode = newValue.code
        }
      }
    },
    programTargetSkillLevelSelect: { // when user choose final category, set program's category.
      get: function () {
        return {
          levelCode: this.programInfo.targetSkillLevelCode,
          levelExplaination: this.programTargetSkillLevelSelectInfo.levelExplaination,
          galleryItems: this.programTargetSkillLevelSelectInfo.galleryItems,
          category: this.programTargetSkillLevelSelectInfo.category,
          categoryNote: this.programTargetSkillLevelSelectInfo.categoryNote,
          subLevelWithinCategory: this.programTargetSkillLevelSelectInfo.subLevelWithinCategory
        }
      },
      // setter when this value is updated
      set: function (newValue) {
        console.log('用户选择项目的目标水平等级：')
        console.log(newValue)
        if (newValue.levelCode) {
          this.programInfo.targetSkillLevelCode = newValue.levelCode
          this.programTargetSkillLevelSelectInfo.galleryItems = newValue.galleryItems
          this.programTargetSkillLevelSelectInfo.levelExplaination = newValue.levelExplaination
          this.programTargetSkillLevelSelectInfo.category = newValue.category
          this.programTargetSkillLevelSelectInfo.categoryNote = newValue.categoryNote
          this.programTargetSkillLevelSelectInfo.subLevelWithinCategory = newValue.subLevelWithinCategory
        }
        console.log('更新至programInfo, programInfo.targetSkillLevelCode:')
        console.log(this.programInfo.targetSkillLevelCode)
      }
    },
    isFormValid: function () {
      if (
        this.isFormVisiblyValid &&
        this.programInfo.imageFileStorageId &&
        this.tagSelectionValidation.isProgramTagValid && this.tagSelectionValidation.isProgramPrerequisiteTagValid && this.tagSelectionValidation.isProgramObjectiveTagValid && this.tagSelectionValidation.isProgramTargetStudentTagValid &&
        this.programInfo.title &&
        this.programInfo.description &&
        this.programInfo.prerequisite &&
        this.programInfo.targetStudent &&
        this.programInfo.trainingObjective &&
        this.programInfo.trainingApproach &&
        this.programInfo.categoryCode
      ) {
        return true
      } else {
        return false
      }
    }
  },
  created: function () {
    this.debouncedAutoSaveProgram = debounce(this.autoSaveProgram, 5000)
    this.debouncedAutoSaveProgramMedia = debounce(this.upsertMedia, 2000)
  },
  data () {
    return {
      programInfo: {
        programId: null,
        organizationId: null,
        tutorId: null,
        title: '',
        description: '',
        imgUrl: '',
        videoFileStorageId: null,
        imageFileStorageId: null,
        publishProgramCommonPropertyId: null,
        editProgramCommonPropertyId: null,
        programCommonPropertyId: null,
        programSpecificPropertySkillLevelId: null,
        moduleLengthMin: 45,
        sessionModuleQuantity: 2,
        maxStudentNumberPerSession: 5,
        trainingObjective: '',
        trainingApproach: '',
        targetStudent: '',
        prerequisite: '',
        entryStudentAgeMonthLowerLimit: 36,
        entryStudentAgeMonthUpperLimit: 72,
        totalModuleQuantity: 20,
        interimAdmission: true,
        descriptionDetail: '',
        statusCode: null,
        categoryCode: null,
        prerequisiteTags: [],
        targetStudentTags: [],
        objectiveTags: [],
        tags: [],
        targetSkillLevelCode: null,
        medias: []
      },
      updatingProgramLock: false,
      programCategory3: [],
      programCategory3Select: { description: '' },
      programCategory2: [],
      programCategory2Select: { description: '' },
      programCategory: [],
      programSkillLevel: [],
      programPrerequisiteSkillLevelSelect: { levelExplaination: '' },
      programTargetSkillLevelSelectInfo: { levelExplaination: '', galleryItems: [] },
      programTargetSkillLevelSelectDisplayingGalleryIndex: 0,
      organizationItems: [],
      programOrganizationSelect: {},
      tutorItems: [],
      moduleLengthMinItems: [1, 5, 15, 30, 45, 60], // program module length selection items
      sessionModuleQuantityItems: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // program session module number selection items
      maxStudentNumberPerSessionItems: [1, 2, 3, 4, 5, 8, 10, 12, 20, 50, 100],
      programPrerequisiteTags: [],
      programTags: [],
      programTargetStudentTags: [],
      programObjectiveTags: [],
      // programMedias: [],
      // venueCompensationInfo: [
      //   {
      //     description: '48次',
      //     quantity: 48,
      //     percentage: 5,
      //     statusCode: 1,
      //     freezing_fund_fen: 100000
      //   }
      // ],
      // classInfo: [
      //   {
      //     name: 'my class',
      //     description: '只在红山片区开展活动',
      //     tutorId: null,
      //     statusCode: 1
      //   }
      // ],
      entryAgeRangeTickLabels: ['婴儿', '', '', '', '', '', '小班', '', '', '', '', '', '1年级', '', '', '', '', '', '', '', '', '', '6年级'],
      rules: {
        // general
        required: v => !!v || '必填',
        selectionRequired: v => (v.length !== 0) || '必填',
        objectSelectionRequired: v => !!v || '必填',
        // programInfo
        progTitleRules: [
          v => this.rules.required(v),
          v => !(v.length > 50) || '请简化题目，并控制在50字(母)以内'
        ],
        progDescriptionRules: [
          v => this.rules.required(v),
          v => !(v.length > 150) || '请简化描述，并控制在150字以内。详情与细节可写入详细描述。'
        ],
        progImgRules: {
          // violationFlag:, 此条可通过programInfo.imgUrl判断。
          msg: '头图为发布的必要项'
        },
        progModuleLengthMinRules: [
          v => this.rules.required(v)
        ],
        progSessionModuleQuantityRules: [
          v => this.rules.required(v)
        ],
        progTrainingObjectiveRules: [
          v => this.rules.required(v),
          v => !(v.length > 150) || '请简化描述，并控制在150字以内。详情与细节可写入详细描述。'
        ],
        progTrainingApproachRules: [
          v => this.rules.required(v),
          v => !(v.length > 150) || '请简化描述，并控制在150字以内。详情与细节可写入详细描述。'
        ],
        progTargetStudentRules: [
          v => this.rules.required(v),
          v => !(v.length > 150) || '请简化描述，并控制在150字以内。详情与细节可写入详细描述。'
        ],
        progPrerequisiteRules: [
          v => this.rules.required(v),
          v => !(v.length > 150) || '请简化描述，并控制在150字以内。详情与细节可写入详细描述。'
        ],
        progTotalModuleQuantityRules: [
          v => this.rules.required(v)
        ],
        progEntryStudentAgeRangeMonthRules: [
          v => this.rules.required(v),
          v => v[1] - v[0] <= 36 || '请将年龄范围缩小至36个月(3年)之内'
        ],
        // venue rule
        venueDescriptionRules: [
          v => this.rules.required(v)
        ],
        venueQuantityRules: [
          v => this.rules.required(v)
        ],
        venueStatusCodeRules: [
          v => this.rules.required(v)
        ],
        venuePercentageRules: [
          v => this.rules.required(v)
        ],
        // class rules
        classNameRules: [
          v => this.rules.required(v)
        ],
        classDescriptionRules: [
          v => this.rules.required(v)
        ]
      },
      tagSelectionValidation: {
        isProgramTagValid: true,
        isProgramObjectiveTagValid: true,
        isProgramTargetStudentTagValid: true,
        isProgramPrerequisiteTagValid: true
      },
      imgURL: '',
      resultURL: '',
      img: '',
      isFormVisiblyValid: true,
      showWait: false,
      error: null,
      autoSaveStartToMonitorChanges: false,
      autoSaveStatus: '已保存',
      dialogProgramImage: false,
      step: 1,
      MediaCategoryLookup
    }
  },
  async beforeMount () {
    this.$store.dispatch('setQueryingState', true)
    // 项目的几种情况： 1. 新建空白项目； 2.修改新项目； 3. 修改草稿； 4. 复制一个项目

    if (this.$store.state.route.params.programInfo) { // 编辑项目
      let updatedProgramInfo = _merge(this.programInfo, this.$store.state.route.params.programInfo)
      this.programInfo = updatedProgramInfo
      this.programInfo.tags = updatedProgramInfo.tags

      // Vue.delete(this.programInfo, 'medias') // medias 属性由程序单独维护
      // 此页面仅为编辑项目之用，故项目common property status 必为 DRAFT；同时去除 携带的 common property id 信息。主要的原因是：从新项目发起编辑，我们需要让该系统自动建立一个副本。
      if (this.programInfo.programCommonPropertyStatusCode === ProgramCommonPropertyStatusLookup.APPROVED) { // 判断common property 是否为已审核(5). 如果是从已发布项目编辑，需要通过去除programCommonPropertyId 的操作来在后端Program.upsert中新建立属性副本
        if (this.programInfo.programCommonPropertyStatusCode) { // 如携带program common property属性，将其置为DRAFT
          this.programInfo.programCommonPropertyStatusCode = ProgramCommonPropertyStatusLookup.DRAFT // DRAFT const ProgramCommonPropertyStatusLookup = { DRAFT: 3, PENDING: 4, APPROVED: 5, isThisType }
          delete this.programInfo.programCommonPropertyStatus
        }
        if (this.programInfo.programCommonPropertyId) { // 如带有common property id信息，将其去除。case1: 如从已发布的项目发起编辑，会自动链接到该项目editProgramCommonPropertyId指向，或重新创建commen property；case2：本身是草稿，由于每个项目仅存一个草稿，自动链接到该项目editProgramCommonPropertyId指向；case3: 如新创建项目，本身不会携带该信息。
          delete this.programInfo.programCommonPropertyId
        }

        // trigger auto save to create a copy
        await this.autoSaveProgram()

        const resProgram = await ProgramService.getProgramsFromCommonProperty({ programCommonPropertyIds: [this.programInfo.programCommonPropertyId], attatchDetailedMedias: true, first: true })
        console.log('已新复制项目：')
        console.log(resProgram)
        this.programInfo.medias = resProgram.data.medias
      }
      console.log(`编辑项目。programInfo: `)
      console.log(this.programInfo)
    } else { // 新建项目
      this.autoSaveStartToMonitorChanges = true
      console.log(`新建项目。this.programInfo: ${this.programInfo}`)

      // set program organization id
      this.programInfo.organizationId = this.$store.state.account.organizations
    }
    // get other info
    /**
     * get organization info
     */
    this.organizationItems = this.$store.state.account.organizations
    if (this.organizationItems.length === 1) {
      this.programOrganizationSelect = this.organizationItems[0]
    }

    this.programCategory3 = await this.getProgramCategory3()
    if (this.programInfo.category3Code) {
      console.log(`this.programInfo.category3Code: ${this.programInfo.category3Code}`)
      let programCategory3Select = this.programCategory3.filter(obj => {
        return obj.code === this.programInfo.category3Code
      })
      console.log(programCategory3Select[0])
      this.programCategory3Select = programCategory3Select[0]
    }
    this.programPrerequisiteTags = await this.getProgramPrerequisiteTags()
    this.programTags = await this.getProgramTags()
    this.programTargetStudentTags = await this.getProgramTargetStudentTags()
    this.programObjectiveTags = await this.getProgramObjectiveTags()
    // Vue.set(this, 'programMedias', this.programInfo.medias)
    this.autoSaveStartToMonitorChanges = true
    this.$store.dispatch('setQueryingState', false)
  },
  methods: {
    async getTutorsByOrganizationId (organizationId) {
      try {
        console.log('\n#getTutorsByOrganizationId start ... \norganizationId:', organizationId)
        const res = await ProgramService.getTutors({ organizationId })
        console.log('Tutor info received! res.data:', res.data)
        return res.data
      } catch (err) {
        return err.response.data
      }
    },
    async getProgramSkillLevelsDefinition ({ programCategory2Codes, fetchGallery }) {
      try {
        console.log('getting program skill levels definition ...')
        const res = await ProgramService.getSkillLevelsDefinition({ programCategory2Codes, fetchGallery })
        console.log(`skill levels definition: ${res.data}`)
        return res.data
      } catch (err) {
        return err
      }
    },
    async getProgramCategory3 () {
      try {
        console.log('getting program category 3 ...')
        const res = await ProgramService.getProgramCategory3()
        console.log(`getProgramcategory3: ${res.data}`)
        return res.data
      } catch (err) {
        return err
      }
    },
    async getProgramCategory2 (category3Codes) {
      try {
        console.log('getting program category 2 ...')
        const res = await ProgramService.getProgramCategory2(category3Codes)
        console.log(`getProgramcategory2: ${res.data}`)
        return res.data
      } catch (err) {
        return err
      }
    },
    async getProgramCategory (category2Codes) {
      try {
        console.log('getting program category ...')
        const res = await ProgramService.getProgramCategory(category2Codes)
        console.log(`getProgramcategory: ${res.data}`)
        return res.data
      } catch (err) {
        return err
      }
    },
    async getProgramPrerequisiteTags () {
      try {
        console.log('getProgramPrerequisiteTags ...')
        const res = await ProgramService.getProgramPrerequisiteTags()
        console.log(`ProgramPrerequisiteTags: ${res.data}`)
        return res.data
      } catch (err) {
        return err
      }
    },
    async getProgramTags () {
      try {
        console.log('getProgramTags ...')
        const res = await ProgramService.getProgramTags()
        console.log(`ProgramTags: ${res.data}`)
        return res.data
      } catch (err) {
        return err
      }
    },
    async getProgramTargetStudentTags () {
      try {
        console.log('getProgramTargetStudentTags ...')
        const res = await ProgramService.getProgramTargetStudentTags()
        console.log(`ProgramTargetStudentTags: ${res.data}`)
        return res.data
      } catch (err) {
        return err
      }
    },
    async getProgramObjectiveTags () {
      try {
        console.log('getProgramObjectiveTags ...')
        const res = await ProgramService.getProgramObjectiveTags()
        console.log(`ProgramObjectiveTags: ${res.data}`)
        return res.data
      } catch (err) {
        return err
      }
    },
    async autoSaveProgram () {
      if (!this.updatingProgramLock) {
        this.updatingProgramLock = true
        this.showWait = true
        console.log(`saving program ... `)
        this.autoSaveStatus = '正在保存...'
        const res = await ProgramService.upsertProgram(this.programInfo)
        console.log(`res: `)
        console.log(res)
        let newProgram = helper.camelize(res.data.program)
        let newProgramSpecificPropertySkillLevel = res.data.programSpecificPropertySkillLevel
        this.programInfo.programId = newProgram.id
        this.programInfo.programCommonPropertyId = newProgram.editProgramCommonPropertyId
        this.programInfo.programSpecificPropertySkillLevelId = newProgramSpecificPropertySkillLevel.id
        this.autoSaveStatus = '已保存'
        this.showWait = false
        this.updatingProgramLock = false
      }
    },
    async approvalRequest () {
      this.showWait = true
      const res = await ProgramService.approvalRequest({ programId: this.programInfo.programId })
      this.showWait = false
      console.log(res)
      this.$router.push({
        name: 'programs'
      })
    },
    updateProgramImage: async function () { // crop image
      if (!this.programInfo.programCommonPropertyId) {
        await this.autoSaveProgram()
      }
      const canvas = this.$refs.clipper.clip() // call component's clip method
      console.log('canvas:')
      console.log(canvas)
      this.resultURL = canvas.toDataURL('image/jpeg', 1) // canvas->image
      console.log('this.resultURL:')
      console.log(this.resultURL)

      let clippedImgFile = null
      canvas.toBlob(async (blob) => {
        console.log('blob:')
        console.log(blob)
        clippedImgFile = new File([blob], 'programThumbnailImage.jpg', { type: blob.type })
        console.log('clippedImgFile:')
        console.log(clippedImgFile)
        // compress the image if necessary
        const resizedImage = await resizeImage({ file: clippedImgFile, maxSize: config.imageMaxSizePx })
        // const imgFileUrl = URL.createObjectURL(resizedImage)
        const imgFile = new File([resizedImage], clippedImgFile.name, { type: resizedImage.type }) // convert blob to file for uploading
        let { imgUrl, fileName } = await AliOssService.uploadToAliOss({ file: imgFile })
        console.log(imgUrl)
        console.log(fileName)
        // attach thumbnail to programCommonProperty
        const res = await ProgramService.attachThumbnail({ programCommonPropertyId: this.programInfo.programCommonPropertyId, fileName })
        console.log('ProgramService.attachThumbnail successful:')
        console.log(res)
        // refresh programInfo
        console.log('refreshing programInfo ... :')
        let newProgramInfo = await ProgramService.getProgramsFromCommonProperty({ programCommonPropertyIds: [this.programInfo.programCommonPropertyId], first: true })
        this.programInfo = _merge(this.programInfo, helper.camelize(newProgramInfo.data))
      }, 'image/jpeg', 0.95)
      this.dialogProgramImage = false
    },
    cancelClippingProgramImage () {
      this.dialogProgramImage = false
      this.imgURL = ''
    },
    isProgramTagValidHandler (e) {
      this.tagSelectionValidation.isProgramTagValid = e
    },
    isProgramObjectiveTagValidHandler (e) {
      this.tagSelectionValidation.isProgramObjectiveTagValid = e
    },
    isProgramTargetStudentTagValidHandler (e) {
      this.tagSelectionValidation.isProgramTargetStudentTagValid = e
    },
    isProgramPrerequisiteTagValidHandler (e) {
      this.tagSelectionValidation.isProgramPrerequisiteTagValid = e
    },
    /**
     * 插入媒体文件 text: 该媒体条目的文字信息； mediaCategory：媒体条目的分类代码；index：插入至
     */
    async insertMedia ({ text = null, mediaCategory, index, refsInputFunIsArray = false }) {
      if (!this.updatingProgramLock) {
        this.updatingProgramLock = true
        console.log('user media input')
        console.log('text: ')
        console.log(text)
        console.log('mediaCategory:')
        console.log(mediaCategory)
        console.log('index:')
        console.log(index)
        let input = null
        let file = null
        let res = null
        let programMediaInfo = null
        switch (mediaCategory) {
          case this.MediaCategoryLookup.TEXT:
            console.log('user text input')
            if (index) console.log(`前序媒体块 index: ${index}; insert after medias[index].id: ${this.programInfo.medias[index].id}`)
            res = await ProgramService.programMediaUpsert({
              programMediaId: null,
              fileName: null,
              text: text,
              categoryCode: MediaCategoryLookup.TEXT,
              programCommonPropertyId: this.programInfo.programCommonPropertyId,
              afterMediaId: index != null ? this.programInfo.medias[index].id : null
            })
            programMediaInfo = res.data.data.programMediaInfo
            console.log('programMediaInfo:')
            console.log(programMediaInfo)
            // 更新项目文件
            // this.programMedias.splice(index + 1 || 0, 0, programMediaInfo)
            this.programInfo.medias.splice(index + 1 || 0, 0, programMediaInfo)
            break
          case this.MediaCategoryLookup.IMAGE:
            console.log('user image input')
            if (refsInputFunIsArray) {
              input = this.$refs.imageFileInput[index]
            } else {
              input = this.$refs.imageFileInput
            }
            file = input.files
            if (file && file[0]) {
              // compress the image if necessary
              const resizedImage = await resizeImage({ file: file[0], maxSize: config.imageMaxSizePx })
              // upload to oss
              const imgFile = new File([resizedImage], `programMediaImage_${file[0].name}`, { type: resizedImage.type }) // convert blob to file for uploading
              let { imgUrl, fileName } = await AliOssService.uploadToAliOss({ file: imgFile })
              console.log(fileName)
              console.log(imgUrl)
              const res = await ProgramService.programMediaUpsert({
                programMediaId: null,
                fileName: fileName,
                text: file[0].name,
                categoryCode: MediaCategoryLookup.IMAGE,
                programCommonPropertyId: this.programInfo.programCommonPropertyId,
                afterMediaId: index != null ? this.programInfo.medias[index].id : null
              })
              const programMediaInfo = res.data.data.programMediaInfo
              console.log('programMediaInfo:')
              console.log(programMediaInfo)
              // 更新项目文件
              // this.programMedias.splice(index + 1 || 0, 0, programMediaInfo)
              this.programInfo.medias.splice(index + 1 || 0, 0, programMediaInfo)
            }
            break
          case this.MediaCategoryLookup.VIDEO:
            console.log('user video input')
            if (refsInputFunIsArray) {
              input = this.$refs.videoFileInput[index]
            } else {
              input = this.$refs.videoFileInput
            }
            file = input.files
            if (file && file[0]) {
              // can compress video here
              const resizedVideo = file[0]
              // upload to oss
              const videoFile = new File([resizedVideo], `programMediaVideo_${file[0].name}`, { type: resizedVideo.type }) // convert blob to file for uploading
              let { imgUrl, fileName } = await AliOssService.uploadToAliOss({ file: videoFile })
              console.log(fileName)
              console.log(imgUrl)
              const res = await ProgramService.programMediaUpsert({
                programMediaId: null,
                fileName: fileName,
                text: file[0].name,
                categoryCode: MediaCategoryLookup.VIDEO,
                programCommonPropertyId: this.programInfo.programCommonPropertyId,
                // afterMediaId: index ? this.programMedias[index].id : null
                afterMediaId: index ? this.programInfo.medias[index].id : null
              })
              const programMediaInfo = res.data.data.programMediaInfo
              console.log('programMediaInfo:')
              console.log(programMediaInfo)
              // 更新项目文件
              // this.programMedias.splice(index + 1 || 0, 0, programMediaInfo)
              this.programInfo.medias.splice(index + 1 || 0, 0, programMediaInfo)
            }
            break
          default:
            break
        }
        this.updatingProgramLock = false
      }
    },
    /**
     * 更新媒体文件
     */
    async upsertMedia ({ media, index }) {
      if (!this.updatingProgramLock && media.id) {
        this.updatingProgramLock = true
        switch (media.categoryCode) {
          case this.MediaCategoryLookup.TEXT:
            console.log('user text change')
            // 更新后端数据
            const res = await ProgramService.programMediaUpsert({
              programMediaId: media.id,
              categoryCode: media.categoryCode,
              programCommonPropertyId: media.programCommonPropertyId,
              text: media.text
            })
            console.log('upsertMedia res:')
            console.log(res)
            break
        }
        this.updatingProgramLock = false
      }
    },
    /**
     * 删除媒体文件
     */
    async deleteMedia ({ media, index }) {
      if (!this.updatingProgramLock) {
        this.updatingProgramLock = true
        console.log('delete this mediaid')
        console.log(media.id)
        await ProgramService.programMediasDelete({ programMediaIds: [media.id] })
        // this.programMedias.splice(index, 1)
        this.programInfo.medias.splice(index, 1)
        this.updatingProgramLock = false
      }
    },
    /**
     * 换位文件
     */
    async swapMedia ({ media1, media2, media1Index, media2Index }) {
      if (!this.updatingProgramLock) {
        this.updatingProgramLock = true
        // 更新前端信息
        const media1PositionString = media1.positionString
        const media2PositionString = media2.positionString
        console.log('media1.positionString')
        console.log(media1.positionString)
        console.log('media2.positionString')
        console.log(media2.positionString)
        console.log('programInfo.medias before: ')
        console.log(this.programInfo.medias)
        Vue.set(this.programInfo.medias, media2Index, this.programInfo.medias[media1Index])
        Vue.set(this.programInfo.medias, media1Index, media2)
        this.programInfo.medias[media2Index].positionString = media2PositionString
        this.programInfo.medias[media1Index].positionString = media1PositionString
        console.log('programInfo.medias after: ')
        console.log(this.programInfo.medias)
        console.log('swap the following media')
        console.log('media1:')
        console.log(media1.fileName)
        console.log('media1Index')
        console.log(media1Index)
        console.log('media2:')
        console.log(media2.fileName)
        console.log('media2Index')
        console.log(media2Index)
        // 更新后台信息
        const res = await ProgramService.programMediaSwap({ programMedia1Id: media1.id, programMedia2Id: media2.id })
        console.log(res)
        this.updatingProgramLock = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .my-clipper {
    width: 100%;
    max-width: 700px;
  }
  .placeholder {
    width: 90%;
    min-height: 100px;
    text-align: center;
    margin: 20px;
    padding: 20px;
    background-color: lightgray;
  }
  .editor {
    width: 100%;
    min-height: 300px;
    border: 1px;
  }
  clipper-upload {
    width: 100%;
    height: 100%;
    min-height: 50px;
    border: 1px;
    object-fit: contain;
  }
  .text-align-vertical-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
